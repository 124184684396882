import React from 'react';

import * as Sentry from '@sentry/browser';

import Error from './Error';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Report to Sentry
    Sentry.withScope(scope => scope.setExtras(info));
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return Error;
    }

    return children;
  }
}

export default ErrorBoundary;
