import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import * as Sentry from '@sentry/browser';

import CssBaseline from '@material-ui/core/CssBaseline';

import store, { history } from './store';

// Import Global Styles
import './App.scss';

import App from './containers/App';
import ErrorBoundry from './containers/ErrorBoundry';

// Init Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

const target = document.querySelector('#root');

render(
  <>
    <CssBaseline />
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ErrorBoundry>
          <App />
        </ErrorBoundry>
      </ConnectedRouter>
    </Provider>
  </>,
  target,
);
