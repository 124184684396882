import React from 'react';

import BodyClassName from 'react-body-classname';

import Loader from '../loader.svg';

import PoweredBy from '../components/PoweredBy';

export default () => (
  <BodyClassName className="loading">
    <div className="view--loading">
      <img src={Loader} alt="Loading Animation" />
      <PoweredBy />
    </div>
  </BodyClassName>
);
