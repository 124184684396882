import React from 'react';

import { Typography } from '@material-ui/core';

const PoweredBy = () => (
  <div className="poweredby-wrap">
    <Typography component="p" className="poweredby">
      Powered by <a target="_blank" href="https://craftpeak.com?utm_source=beerquest" rel="noopener noreferrer">Craftpeak</a>
    </Typography>
  </div>
);

export default PoweredBy;
