// Account/Map Actions
export const SET_ACCOUNT = 'finder/SET_ACCOUNT';
export const UNSET_ACCOUNT = 'finder/UNSET_ACCOUNT';

// Filter Actions
export const SET_PROXIMITY = 'finder/SET_PROXIMITY';
export const SET_BRAND = 'finder/SET_BRAND';
export const SET_AVAILABILITY = 'finder/SET_AVAILABILITY';
export const RESET_FILTERS = 'finder/RESET_FILTERS';

export const initialState = {
  account: false,
  proximity: 20,
  brand: '',
  availability: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.account,
      };

    case UNSET_ACCOUNT:
      return {
        ...state,
        account: false,
      };

    case SET_PROXIMITY:
      return {
        ...state,
        proximity: action.proximity,
      };

    case SET_BRAND:
      return {
        ...state,
        brand: action.brand,
      };

    case SET_AVAILABILITY:
      return {
        ...state,
        availability: action.availability,
      };

    case RESET_FILTERS:
      return {
        ...state,
        proximity: initialState.proximity,
        brand: initialState.brand,
        availability: initialState.availability,
      };

    default:
      return state;
  }
};

// Account/Map Actions
export const setAccount = account => (dispatch) => {
  dispatch({
    type: SET_ACCOUNT,
    account,
  });
};

export const unsetAccount = () => (dispatch) => {
  dispatch({
    type: UNSET_ACCOUNT,
  });
};

// Filter Actions
export const setProximity = proximity => (dispatch) => {
  dispatch({
    type: SET_PROXIMITY,
    proximity,
  });
};

export const setBrand = brand => (dispatch) => {
  dispatch({
    type: SET_BRAND,
    brand,
  });
};

export const setAvailability = availability => (dispatch) => {
  dispatch({
    type: SET_AVAILABILITY,
    availability,
  });
};

export const resetFilters = () => (dispatch) => {
  dispatch({
    type: RESET_FILTERS,
  });
};

// Selectors
export const areFiltersModified = ({
  proximity,
  brand,
  availability,
}) => (proximity !== initialState.proximity || brand || availability);
