export default {
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#111111',
    },
  },
  shadows: new Array(25).fill('none'),
  shape: {
    borderRadius: 2,
  },
  spacing: 10,
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 400,
    },
    h2: {
      fontSize: '1.8rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '0.8rem',
      fontWeight: 500,
    },
  },
};
