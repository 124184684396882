import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import app from './app';
import location from './location';
import finder from './finder';

export default history => combineReducers({
  router: connectRouter(history),
  app,
  location,
  finder,
});
