import { push } from 'connected-react-router';

export const SET_LOCATION = 'location/SET_LOCATION';

export const initialState = {
  geo: '',
  address: '',
  method: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        address: action.address,
        geo: action.geo,
        method: action.method,
      };

    default:
      return state;
  }
};

export const setLocation = (address, geo, method) => (dispatch) => {
  dispatch({
    type: SET_LOCATION,
    address,
    geo,
    method,
  });
};

// Helper to change application state of the map with coordinates
export const changeLocation = (latLng, queryParams = '') => (dispatch) => {
  dispatch(push(`/${latLng.lat},${latLng.lng}/${queryParams}`));
};
